/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var HISC = {
    constants: {
      BODY: $('body')
    },

    // All pages
    'common': {
      init: function () {
        AOS.init({
          offset: 60,
          duration: 800
        });

        $('.countup').each(function() {
          var el = this;

          new Waypoint({
            element: this,
            handler: function() {
              counterUp.default(el);
              this.destroy();
            },
            offset: 'bottom-in-view'
          });
        });

        var $header = $('#header-fixed'),
          $wrap = $('#wrap');

        $(window).on('resize', function () {
          $wrap.css({
            marginTop: $header.outerHeight()
          });
        }).trigger('resize');

        HISC.common.territoryFinder();

        // !-- ENABLE THIS IF VIDEO IFRAMES ARE THROWING ERRORS --! \\
        /* function disableConsole(element) {
          var iFrameWindow = element.contentWindow;
          iFrameWindow.console.log = function(){};
        }
        var videos = document.getElementsByClassName('video-video');
        videos.onload = function() {
          videos.forEach(disableConsole);
        } */
        // !-- ENABLE THIS IF VIDEO IFRAMES ARE THROWING ERRORS --! \\

        $(document).on('click', '.video-gallery .video-wrapper', function(e) {
          // Toggles Active Class
          $('.video-gallery .video-wrapper').removeClass('active');
          $(this).addClass('active');
        });

        function toggleNavbarIfSamePageLink(){
          var toggleBtn = $('body').find('#toggle-nav');
          var navBar = $('body').find('#primary-navbar-collapse');

          console.log(toggleBtn.length, navBar.length);
          if(toggleBtn.length > 0 && navBar.length > 0){
            //if(!toggleBtn.hasClass('collapsed')){
              navBar.collapse('hide');
            //}
          }
        }

        function scrollToAdjustment(id){
          var offset = 60;
          if($(window).width() > 992){
            offset = 121;
          }
          $('html,body').animate({scrollTop: $(id).offset().top - offset}, 500);
        }

        $(document).on('click', 'body.home #menu-item-10 a', function(e){
          e.preventDefault();
          var id = $("#home-body-contained");
          if(id.length > 0){
            toggleNavbarIfSamePageLink();
            scrollToAdjustment(id);
          }
        });

        $(document).on('click', 'body.home #menu-item-13 a', function(e){
          e.preventDefault();
          var id = $("#home-map");
          if(id.length > 0){
            toggleNavbarIfSamePageLink();
            scrollToAdjustment(id);
          }
        })

        $(document).on('click', 'a[class="scroll"][href^="#"]', function(e){
            e.preventDefault();
            var id = $(this).attr('href');
            scrollToAdjustment(id);
        });

      },
      territoryFinder: function() {
        $(document).on('click', '.territory-finder-current-location', function(e) {
          e.preventDefault();

          if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(pos) {
              $('.territory-finder-location-text').val('My location');
              $('.territory-finder-latlng').val([pos.coords.latitude, pos.coords.longitude].join(','));
              $('.territory-finder-form').submit();
            });
          } else {
            alert('Couldn\'t get your current location. Please try typing a location into the box instead.');
          }

          return false;
        });

        $(document).on('submit', '.territory-finder-form', function(e) {
          e.preventDefault();

          $('.territories-result').show();

          var $this = $(this);

          $.ajax({
            url: $this.attr('action') + '/wp-admin/admin-ajax.php',
            type: 'post',
            data: {
              action: 'territory_finder',
              location_text: $('.territory-finder-location-text').val(),
              latlng: $('.territory-finder-latlng').val()
            },
            success: function(response) {
              $('.territories-result').show().html(response);
            }
          });

          return false;
        });
      },
      finalize: function () {
        $('#case-studies-sidebar').slick({
          arrows: false,
          dots: true,
          autoplay: true,
          adaptiveHeight: true
        });

        $(document).on('click', 'a[href="#search"]', function(e) {
          e.preventDefault();
          HISC.constants.BODY.addClass('navbar-search-form-open');
          return false;
        });

        $('#header-fixed').on('click', function(e) {
          if(HISC.constants.BODY.hasClass('navbar-search-form-open') && $(e.target).is('div')) {
            HISC.constants.BODY.removeClass('navbar-search-form-open');
          }
        });

        $('.has-modal-video').on('click', function(e) {
          e.preventDefault();
          return false;
        }).modalVideo();
      }
    },
    // Home page
    'home': {
      init: function () {
        var video = $('#home-video');
        var posterVideo = $('#poster-loop').get(0);
        var cnv = $('#home-video-main-ambilight').get(0);
        var $mainVideo = $('#home-video-main');
        var ambilight;

        var normaliseVideoAndAmbilight = function() {
          cnv.width = $(window).width();
          cnv.height = $mainVideo.height();

          var width = parseInt($mainVideo.css('height'), 10) * 1.77777;
          width = Math.min(width, $(window).width());

          $mainVideo.css({
            width: width
          });
        };

        var startAmbilight = function() {
          normaliseVideoAndAmbilight();

          ambilight = window.setInterval(function() {
            cnv.getContext('2d').drawImage(document.getElementById('home-video-main'), 0, 0);
          }, 30);
        };

        $(window).on('resize', normaliseVideoAndAmbilight);

        var stopAmbilight = function() {
          if(ambilight)
          {
            clearInterval(ambilight);
          }
        };

        if(!posterVideo)
        {
          video.addClass('image-poster');
        }
        else
        {
          video.find('.video-poster').addClass('darken');
        }

        var mainVideo = $mainVideo.on('ended', function() {
          video.removeClass('main-video-play');
          video.find('video').removeAttr('controls');

          if(posterVideo)
          {
            posterVideo.play();
          }
          else
          {
            video.find('video').get(0).load();
          }
        }).get(0);

        $('#home-video-main-play').on('click', function() {
          video.addClass('main-video-play');
          video.find('video').attr('controls', 'controls');

          if(posterVideo)
          {
            posterVideo.pause();
          }

          mainVideo.play();
        });

        mainVideo.addEventListener('play', startAmbilight);
        mainVideo.addEventListener('pause', stopAmbilight);
        mainVideo.addEventListener('ended', stopAmbilight);

        var contentVideoPlay = $('#home-video-content-play'),
          contentVideo = $('#home-video-content').on('ended', function() {
            contentVideo.controls = false;
            contentVideo.load();
            contentVideoPlay.parent().removeClass('active');
          }).get(0);

        contentVideoPlay.on('click', function() {
          contentVideo.play();
          contentVideo.controls = true;
          contentVideoPlay.parent().addClass('active');
        });
      },
      finalize: function () {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'open_days': {
      googleMapsInit: function() {
        (function($) {

          /*
           *  new_map
           *
           *  This function will render a Google Map onto the selected jQuery element
           *
           *  @type	function
           *  @date	8/11/2013
           *  @since	4.3.0
           *
           *  @param	$el (jQuery element)
           *  @return	n/a
           */

          function new_map( $el ) {

            /*
             *  add_marker
             *
             *  This function will add a marker to the selected Google Map
             *
             *  @type	function
             *  @date	8/11/2013
             *  @since	4.3.0
             *
             *  @param	$marker (jQuery element)
             *  @param	map (Google Map object)
             *  @return	n/a
             */

            function add_marker( $marker, map ) {

              // var
              var latlng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );

              // create marker
              var marker = new google.maps.Marker({
                position	: latlng,
                map			: map
              });

              // add to array
              map.markers.push( marker );

              // if marker contains HTML, add it to an infoWindow
              if( $marker.html() )
              {
                // create info window
                var infowindow = new google.maps.InfoWindow({
                  content		: $marker.html()
                });

                // show info window when marker is clicked
                google.maps.event.addListener(marker, 'click', function() {

                  infowindow.open( map, marker );

                });
              }

            }

            /*
             *  center_map
             *
             *  This function will center the map, showing all markers attached to this map
             *
             *  @type	function
             *  @date	8/11/2013
             *  @since	4.3.0
             *
             *  @param	map (Google Map object)
             *  @return	n/a
             */

            function center_map( map ) {

              // vars
              var bounds = new google.maps.LatLngBounds();

              // loop through all markers and create bounds
              $.each( map.markers, function( i, marker ){

                var latlng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );

                bounds.extend( latlng );

              });

              // only 1 marker?
              if( map.markers.length === 1 )
              {
                // set center of map
                map.setCenter( bounds.getCenter() );
                map.setZoom( 16 );
              }
              else
              {
                // fit to bounds
                map.fitBounds( bounds );
              }

            }

            // var
            var $markers = $el.find('.marker');


            // vars
            var args = {
              zoom		: 16,
              center		: new google.maps.LatLng(0, 0),
              mapTypeId	: google.maps.MapTypeId.ROADMAP
            };


            // create map
            var map = new google.maps.Map( $el[0], args);


            // add a markers reference
            map.markers = [];


            // add markers
            $markers.each(function(){

              add_marker( $(this), map );

            });


            // center map
            center_map( map );


            // return
            return map;

          }





          /*
           *  document ready
           *
           *  This function will render each map when the document is ready (page has loaded)
           *
           *  @type	function
           *  @date	8/11/2013
           *  @since	5.0.0
           *
           *  @param	n/a
           *  @return	n/a
           */
// global var
          var map = null;

          $(document).ready(function(){

            $('.acf-map').each(function(){

              // create map
              map = new_map( $(this) );

            });

          });

        })(jQuery);
      }

    },
    'contact_us': {
      googleMapsInit: function() {
        var element = document.getElementById('contact-map'),
          latlng = new google.maps.LatLng(53.3276895,-2.568288),
          map = new google.maps.Map(element, {
            mapTypeId: google.maps.MapTypeId.TERRAIN,
            center: latlng,
            zoom: 9
          }),
          marker = new google.maps.Marker({
            position: latlng,
            map: map,
            title: 'LPCS',
            icon: WP.template_directory_uri + '/dist/images/map-marker.png'
          });
      }
    },
    'page_template_page_faq_php': {
      init: function() {
        var modal = $('#faq-modal');

        $('.faq-question').on('click', function() {
          modal.modal('show');
          modal.find('#modal-faq-question').text($(this).text());
          modal.find('#modal-faq-answer').html($(this).next('.faq-answer').html());
        });
      }
    },
    'page_template_page_our_territories': {
      init: function() {
        
        $('body').on('click','.territory',function(){

          var territoryName = $(this).find('.territory-name').html();
          var territoryModal = $('body').find('#territories-modal');

          if(territoryModal){
            territoryModal.addClass('vertical-alignment-helper');
            var modalBody = territoryModal.find('.modal-body');

            if(modalBody){
              //gravity form - input_3_6 - territory interested in
              var territoryInput = modalBody.find('#input_3_6');
              if(territoryInput){
                //console.log("found input");
                territoryInput.val(territoryName);
              }
              territoryModal.removeClass('hidden');
            }

            var newForm = $('body').find('#new-form');
            newForm.removeClass('hidden');
            territoryModal.appendTo('body').modal('show');
          }
        });

        $('body').on('show.bs.modal','#territories-modal', function(e){
          var form = $('body').find('#gform_confirmation_wrapper_3');
          form.find('br').remove();
        });

        $('body').on('hidden.bs.modal','#territories-modal', function(e){
          
          var territoryModal = $('body').find('#territories-modal');
          var submittedForm = $('body').find('#new-form');
          var gFormForm = $('body').find('#gform_3');

          if(territoryModal){
            var modalBody = territoryModal.find('.modal-body');
            if(modalBody){
              territoryModal.addClass('hidden');
            }
          }
          if(submittedForm){
            //reset form values when form is submitted
            if(gFormForm){
              $(gFormForm).find('.gform_body input').val('');
              $(gFormForm).find('.gfield_error').removeClass('gfield_error');
              $(gFormForm).find('.validation_message').remove();
              $('body').find('.confirmation-message').remove();
            }

            //make sure form is visible for next time
            submittedForm.removeClass('hidden');
          }
        });

        var territoryMap = $('#image-territory-map');

        if(territoryMap.length) {
          territoryMap.mapster({
            fillOpacity: 0
          });

          $('area').qtip({
            content: {
              text: function () {
                var territory = $(this).data('key');
                var territoryNames = $(this).data('territories');

                if (territoryNames) {
                  territoryNames = territoryNames.split(',').join('<br>');
                  territoryNames += '<br><br><a href="/contact-us/" class="btn btn-primary">Enquire Now</a>';
                }
                else {
                  territoryNames = 'We do not have any territories available in ' + territory + ' at the moment.';
                }

                return '<strong>' + territory + '</strong><br>' + territoryNames;
              }
            },
            position: {
              my: 'center middle',
              at: 'center middle'
            },
            style: {
              classes: 'qtip-bootstrap qtip-hisc'
            },
            show: {
              delay: 600
            },
            hide: {
              fixed: true
            }
          });
        }

        $(window).on('resize', function() {
          var image =  territoryMap,
            imgWidth = image.width(),
            imgHeight = image.height(),
            newWidth=0,
            newHeight= 0,
            maxWidth = $(window).width(),
            maxHeight = $(window).height();

          if(maxWidth < 540) {
            maxWidth -= 40;

            if (imgWidth / maxWidth > imgHeight / maxHeight) {
              newWidth = maxWidth;
            }
            else {
              newHeight = maxHeight;
            }
          }

          image.mapster('resize',newWidth,newHeight,0);
        }).trigger('resize');
      }
    },
    'page_template_page_territory_resales': {
      init: function() {
        $('[data-territory]').on('click', function() {
          $('#input_3_6').val($(this).data('territory'));
        });
      }
    },
    'page_template_page_find_out_more': {
      init: function() {

        $('.case-studies-landing').slick({
          adaptiveHeight: true,
          slidesToShow: 1,
          prevArrow: '<i class="fa fa-angle-left"></i>',
          nextArrow: '<i class="fa fa-angle-right"></i>'
        });

        $('body').on('click', '.join-us-cta', function(e){

          var captureModal = $('body').find('#modal-capture');
          captureModal.removeClass('hidden');
        });

        $('body').on('hidden.bs.modal', '#modal-capture', function(e){
          var captureModal = $('body').find('#modal-capture');

          var modalBody = captureModal.find('.modal-body');
          if(modalBody){
            captureModal.addClass('hidden');
          }
        });


      }
    },
    'page_template_page_resale_child': {
      init: function() {

        $('body').on('click','#resale-enquiry',function(){

          var resaleSlug = $(this).attr('data-slug');
          var resalesModal = $('body').find('#resales-modal');

          if(resalesModal){
            resalesModal.addClass('vertical-alignment-helper');
            var modalBody = resalesModal.find('.modal-body');

            if(modalBody){
              //gravity form - input_10_6 - slug field is what we're interested in here
              var resalesInput = modalBody.find('#input_10_6'); //slug - hidden field
              if(resalesInput){
                resalesInput.val(resaleSlug);
              }
              resalesModal.removeClass('hidden');
            }

            var newForm = $('body').find('#new-form');
            newForm.removeClass('hidden');
            resalesModal.appendTo('body').modal('show');
          }
        });

        $('body').on('show.bs.modal','#resales-modal', function(e){
          var form = $('body').find('#gform_confirmation_wrapper_10');
          form.find('br').remove();
        });

        $('body').on('hidden.bs.modal','#resales-modal', function(e){
          
          var resalesModal = $('body').find('#resales-modal');
          var submittedForm = $('body').find('#new-form');
          var gFormForm = $('body').find('#gform_10');

          if(resalesModal){
            var modalBody = resalesModal.find('.modal-body');
            if(modalBody){
              resalesModal.addClass('hidden');
            }
          }
          if(submittedForm){
            //reset form values when form is submitted
            if(gFormForm){
              $(gFormForm).find('.gform_body input').val('');
              $(gFormForm).find('.gfield_error').removeClass('gfield_error');
              $(gFormForm).find('.validation_message').remove();
              $('body').find('.confirmation-message').remove();
            }

            //make sure form is visible for next time
            submittedForm.removeClass('hidden');
          }
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function (func, funcname, args) {
      var fire;
      var namespace = HISC;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvent: function(event) {
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
        UTIL.fire(classnm, event);
      });
    },
    loadEvents: function () {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      UTIL.loadEvent('init');
      UTIL.loadEvent('finalize');

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  window.googleMapsInit = function() {
    UTIL.loadEvent('googleMapsInit');
  };

})(jQuery); // Fully reference jQuery after this point.
